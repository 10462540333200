import React, { useState } from 'react'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Logo from '../assets/images/logo/royal-international-logo.png'
import { menuItems } from '../Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';

const Header = () => {
    const [sideMenu, setSideMenu] = useState(false)
    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setSideMenu({ sideMenu, 'left': open });
    };
    return (
        <div id="header">
            <Container>
                {/* Mobile Menu */}
                <SwipeableDrawer
                    open={sideMenu['left']}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Box
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <img src={Logo} alt="Royal International Logo" className="img-fluid p-3" />
                        <Divider />
                        <List>
                            {menuItems.map((menu, index) => (
                                <a href={menu.path}>
                                    <ListItem button key={index}>
                                        <ListItemIcon>
                                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                                        </ListItemIcon>
                                        <ListItemText primary={menu.label} />
                                    </ListItem>
                                </a>
                            ))}
                        </List>
                    </Box>
                </SwipeableDrawer>
                {/* END: Mobile Menu */}
                <Link to="/">
                    <img src={Logo} alt="Royal International Logo" className="img-fluid" />
                </Link>
                <Button onClick={toggleDrawer(true)} className="d-block d-sm-none menuicon"><MenuIcon /></Button>
                <nav id="navbar" className="navbar d-none d-sm-block">
                    <ul>
                        {menuItems.map((menu, index) => {
                            return (
                                <li key={index}>
                                    <a href={menu.path}>{menu.label}</a>
                                </li>
                            )
                        })}
                    </ul>
                </nav>
            </Container>
        </div>
    )
}

export default Header

import React from 'react'
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Col, Container, Row } from 'react-bootstrap'

const Footer = () => {
    return (
        <div id="footer">
            <section>
                <Container>
                    <Row>
                        <Col md={3} xs={5}>
                            <h5>Quick Links</h5>
                            <ul>
                                <li><h6><a href="/#header">Home</a></h6></li>
                                <li><h6><a href="/#about">About Us</a></h6></li>
                                <li><h6><a href="/#products">Products</a></h6></li>
                                <li><h6><a href="/#contact">Contact Us</a></h6></li>
                            </ul>
                        </Col>
                        <Col md={3} xs={7}>
                            <h5>Proudcts</h5>
                            <ul>
                                <li><h6><a href="/#products">Cashew</a></h6></li>
                                <li><h6><a href="/#products">Almond</a></h6></li>
                                <li><h6><a href="/#products">Pistachio</a></h6></li>
                                <li><h6><a href="/#products">Raisins</a></h6></li>
                            </ul>
                        </Col>
                        <Col md={3} xs={5}>
                            <h5>Proudcts</h5>
                            <ul>
                                <li><h6><a href="/#products">Dry Apricot</a></h6></li>
                                <li><h6><a href="/#products">Walnut</a></h6></li>
                                <li><h6><a href="/#products">Black Raisins</a></h6></li>
                                <li><h6><a href="/#products">Dry Figs</a></h6></li>
                            </ul>
                        </Col>
                        <Col md={3} xs={7}>
                            <h5>Social Media</h5>
                            <ul>
                                <li><h6><a href="#!"><TwitterIcon /> @royalinternational</a></h6></li>
                                <li><h6><a href="#!"><FacebookIcon /> /royalinternational</a></h6></li>
                                <li><h6><a href="#!"><InstagramIcon /> /royalinternational</a></h6></li>
                            </ul>
                        </Col>
                    </Row>
                    <hr />
                    <div className="copyright">
                        <p>&copy; {new Date().getFullYear()} Royal International. All rights reserved. Website designed and developed by Arkay Apps Pvt. Ltd.</p>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default Footer

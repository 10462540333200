export const menuItems = [
    {
        label: 'Home',
        path: '/#header',
        icon: '',
    },
    {
        label: 'About Us',
        path: '/#about',
        icon: '',
    },
    {
        label: 'Products',
        path: '/#products',
        icon: '',
    },
    {
        label: 'Contact Us',
        path: '/#contact',
        icon: '',
    }
]

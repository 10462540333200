import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
import Slider from '../components/Slider'
import Design4 from '../assets/images/design-4.png'
import Design5 from '../assets/images/design-5.png'
import Design7 from '../assets/images/design-7.png'
import RoomIcon from '@mui/icons-material/Room';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Almond from '../assets/images/products/almond.png'
import AlmondFull from '../assets/images/products/almond-full.png'
import BlackRaisins from '../assets/images/products/black-raisins.jpg'
import BlackRaisinsFull from '../assets/images/products/black-raisins-full.png'
import Cashew from '../assets/images/products/cashew.jpg'
import CashewFull from '../assets/images/products/cashew-full.png'
import DryApricot from '../assets/images/products/dry-apricot.png'
import DryApricotFull from '../assets/images/products/dry-apricot-full.png'
import DryFigs from '../assets/images/products/dry-figs.jpg'
import DryFigsFull from '../assets/images/products/dry-figs-full.png'
import Pistachio from '../assets/images/products/pistachio.jpg'
import PistachioFull from '../assets/images/products/pistachio-full.png'
import Raisins from '../assets/images/products/raisins.png'
import RaisinsFull from '../assets/images/products/raisins-full.png'
import Walnut from '../assets/images/products/walnut.png'
import WalnutFull from '../assets/images/products/walnut-full.png'
import Dates from '../assets/images/products/dates.png'
import DatesFull from '../assets/images/products/dates-full.png'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { Dialog } from '@mui/material';

const Homepage = () => {
    const [open, setOpen] = useState(false);
    const [fullImage, setFullImage] = useState(CashewFull)
    const [fullImageAlt, setFullImageAlt] = useState('Cashew')

    const showFullImage = (image, imageAlt) => {
        setFullImage(image)
        setFullImageAlt(imageAlt)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <MetaTags>
                <title>Royal International | Premium Quality Dry Fruit</title>
                <meta name="description" content="Royal International is the brand of rich quality Pistachio, Cashew, Almond, Walnuts, and Black Raising." />
            </MetaTags>
            <Slider />
            <section id="about" className="section-bg">
                <Container>
                    <h2>About Royal International</h2>
                    <p>Our brand, Royal International, is a renowned supplier of rich quality Pistachios, Cashew Nuts, Almonds, Walnuts, and Black Raisins. We provide premium export quality Dry Fruits all over the world. The company is headquartered in India – Asia.</p>
                    <p>We are native to India (Asia). The company has an in-house processing facility and skilled team for procuring, supplying, and storage of rich nuts.</p>
                </Container>
            </section>
            <section id="products">
                <Container>
                    <h2>Best Proudcts</h2>
                    <p>We are dry fruits retailer and Wholesaler. Royal International has its own network dealing with the import of Raw Materials and export of the processed nuts and dry fruits. The company’s processing plant is based in India-Asia and is efficient to provide Fresh, Pure, and Natural dry fruits at a very cost-effective price.</p>
                    <Row>
                        <Col md={3}>
                            <div className="product">
                                <img src={Cashew} alt="Cashew Nuts" className="img-fluid" onClick={() => showFullImage(CashewFull, 'Cashew Nuts')} />
                                <div className="info">
                                    <h3>Cashew Nuts</h3>
                                    <p>Packaging: <br /> 25KG, 10KG, 500GM, 250GM</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="product">
                                <img src={Almond} alt="Almonds" className="img-fluid" onClick={() => showFullImage(AlmondFull, 'Almonds')} />
                                <div className="info">
                                    <h3>Almonds</h3>
                                    <p>Packaging: <br /> 25KG, 10KG, 500GM, 250GM</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="product">
                                <img src={Pistachio} alt="Pistachios" className="img-fluid" onClick={() => showFullImage(PistachioFull, 'Pistachios')} />
                                <div className="info">
                                    <h3>Pistachios</h3>
                                    <p>Packaging: <br /> 25KG, 10KG, 500GM, 250GM</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="product">
                                <img src={Raisins} alt="Raisins" className="img-fluid" onClick={() => showFullImage(RaisinsFull, 'Raisins')} />
                                <div className="info">
                                    <h3>Raisins</h3>
                                    <p>Packaging: <br /> 25KG, 10KG, 500GM, 250GM</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="product">
                                <img src={DryApricot} alt="Dry Apricots" className="img-fluid" onClick={() => showFullImage(DryApricotFull, 'Dry Apricots')} />
                                <div className="info">
                                    <h3>Dry Apricots</h3>
                                    <p>Packaging: <br /> 25KG, 10KG, 500GM, 250GM</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="product">
                                <img src={Walnut} alt="Walnuts" className="img-fluid" onClick={() => showFullImage(WalnutFull, 'Walnuts')} />
                                <div className="info">
                                    <h3>Walnuts</h3>
                                    <p>Packaging: <br /> 25KG, 10KG, 500GM, 250GM</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="product">
                                <img src={BlackRaisins} alt="Black Raisins" className="img-fluid" onClick={() => showFullImage(BlackRaisinsFull, 'Black Raisins')} />
                                <div className="info">
                                    <h3>Black Raisins</h3>
                                    <p>Packaging: <br /> 25KG, 10KG, 500GM, 250GM</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="product">
                                <img src={DryFigs} alt="Dry Figs" className="img-fluid" onClick={() => showFullImage(DryFigsFull, 'Dry Figs')} />
                                <div className="info">
                                    <h3>Dry Figs</h3>
                                    <p>Packaging: <br /> 25KG, 10KG, 500GM, 250GM</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="product">
                                <img src={Dates} alt="Dates" className="img-fluid" onClick={() => showFullImage(DatesFull, 'Dates')} />
                                <div className="info">
                                    <h3>Dates</h3>
                                    <p>Packaging: <br /> 25KG, 10KG, 500GM, 250GM</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <img src={fullImage} alt={fullImageAlt} className="img-fluid" />
                    </Dialog>
                </Container>
            </section>
            <img src={Design5} alt="Design" className="design-5 img-fluid" />
            <section className="design-2">
                <Container>
                    <Row>
                        <Col md={6}>
                            <h2>Provide Customise Packing <br /> Option!</h2>
                        </Col>
                    </Row>
                </Container>
                <img src={Design4} alt="Design" className="img-fluid design-4 float-end" />
            </section>
            <section id="contact">
                <Container>
                    <h2>Feel Free To Reach Us</h2>
                    <Row className="justify-content-center">
                        <Col md={3}>
                            <div className="details">
                                <LocalPhoneIcon />
                                <h3>Give Us call</h3>
                                <a href="tel:+91 94272 50258">+91 94272 50258</a> <br />
                                <a href="tel:+91 63536 45864">+91 63536 45864</a>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="details">
                                <WhatsAppIcon />
                                <h3>WhatsApp-Us</h3>
                                {/* eslint-disable-next-line */}
                                <a href="https://api.whatsapp.com/send/?phone=919427250258&text=Hello%0A%0A%0AI+have+visited+your+website+Royal+International+and+im+intrested+in+your+products" target="_blank">+91 94272 50258</a> <br />
                                {/* eslint-disable-next-line */}
                                <a href="https://api.whatsapp.com/send/?phone=916353645864&text=Hello%0A%0A%0AI+have+visited+your+website+Royal+International+and+im+intrested+in+your+products" target="_blank">+91 63536 45864</a>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="details">
                                <EmailIcon />
                                <h3>Email Us</h3>
                                <a href="mailto:export@royalinterntl.com">export@royalinterntl.com</a> <br />
                                <a href="mailto:info@royalinterntl.com">info@royalinterntl.com</a>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="details">
                                <RoomIcon />
                                <h3>Visit Us</h3>
                                <p>Madhapar Highway,  Kutch,<br />Gujarat-370020, India</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <img src={Design7} alt="Design" className="design-7 d-none d-sm-block" />
        </div>
    )
}

export default Homepage

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Slider = () => {
    return (
        <div>
            <section id="intro">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={7}>
                            <h1>Premium Quality Dry Fruit</h1>
                            <p>We “Royal International” are Wholesalers, Traders, and Exporters of high-quality assortments of Pistachios, Cashew Nuts, Almonds, Walnuts, Black Raisins, Dry Figs, Dry Apricots, etc.</p>
                            <a className="btn-primary" href="/#products">Explore Products</a>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Slider
